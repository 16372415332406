<template>
  <div id="TaskManagementDetail" style="background: white; border-radius: 5px;" class="pb-5">
    <div class="card-header"
         style="display: flex; justify-content: space-between; align-items: center; padding: 15px 20px; scroll-behavior: smooth;">
      <h4 style="margin-bottom: 0;">Chi tiết tác vụ</h4>
    </div>
    <div>
      <div>
        <div class="m-4 d-flex justify-content-end mr-10">

        </div>
      </div>
      <div>
        <div class="col-md-12 d-flex" id="detailTask">
          <div class="col-xl-6 col-lg-12">
            <div class=d-block>
              <div class="mb-10">
                <taskInfo
                    :taskInfo="listTaskInfo">
                </taskInfo>
              </div>
              <div style="padding-left: 12.5px">
                <h5>Trạng thái</h5>
                <span class="p-1 border border-1 rounded" :class="getBGStatus(listTaskInfo?.status)"
                      style="   font-size: 11px;">
                {{ listTaskInfo?.status }}
              </span>
              </div>
              <!-- Danh sách lớp đang tuyển sinh -->
              <!-- <div class="mt-4">
                <ListAdmissions
                :listClass="listClass">
                </ListAdmissions>
              </div> -->
            </div>
          </div>
          <div class="col-xl-6">
            <div class="d-block">
              <div v-if="!listTaskInfo.is_over">
                <h5 style="margin-bottom: 0;" class="mb-5" for="">Nhập kết quả xử lý</h5>
                <button v-if="!boxResultPhone" @click="boxResultPhone = true"
                        style="width: 100%; height: 51px; text-align: start; border: solid 2px rgba(94, 0, 182, 1) !important; color: rgba(0, 0, 0, 0.5);"
                        class="border rounded border-1 pl-4 purple">Bấm vào để Nhập kết quả xử lý
                </button>
                <div v-if="boxResultPhone">
                  <ResultPhoneHistoryComponent
                      :taskInfor="listTaskInfo"
                      @showBoxResultPhone="boxResultPhone = false"
                      @loadData="getHistoryLogTicket()"
                  ></ResultPhoneHistoryComponent>
                </div>
              </div>
            </div>
            <div class="mb-5 mt-5">
              <h5 style="margin:0 0 20px 0px">Lịch sử xử lý</h5>
              <template class="w-100">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="Tất cả" :name="ALL_HISTORY.toString()">
                  </el-tab-pane>
                  <el-tab-pane label="Xếp lớp" :name="XEP_LOP.toString()">
                  </el-tab-pane>
                  <el-tab-pane label="Xử lý fail khóa" :name="XU_LY_FALL_KHOA.toString()">
                  </el-tab-pane>
                  <el-tab-pane label="Xử lý pass khóa" :name="XU_LY_PASS_KHOA.toString()">
                  </el-tab-pane>
                  <el-tab-pane label="Khai giảng" :name="KHAI_GIANG.toString()">
                  </el-tab-pane>
                  <takeCareOfHistory
                      :log="logHistory"
                      :tabName="activeName"
                  >
                  </takeCareOfHistory>
                </el-tabs>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import TaskInfo from "../../pages/task-management/components/TaskInfo.vue";
import ListAdmissions from "../../pages/task-management/components/ListAdmissions.vue";
import takeCareOfHistory from "../../pages/task-management/components/takeCareOfHistory.vue";
import ResultPhoneHistoryComponent from "../../pages/task-management/components/ResultPhoneHistoryComponent.vue";
import {GET_DETAIL_TICKET_CLASS, GET_LOG_HISTORY} from "@/core/services/store/task-management/task-management.module";
import {ALL_HISTORY, KHAI_GIANG, XEP_LOP, XU_LY_FALL_KHOA, XU_LY_PASS_KHOA} from "@/core/option/taskArrangePersonal";

export default {
  name: "TaskManagementDetail",
  components: {
    TaskInfo, ListAdmissions, takeCareOfHistory, ResultPhoneHistoryComponent
  },
  data() {
    return {
      result_phone_call: '',
      boxResultPhone: false,
      task_id: '',
      task_type: '',
      activeName: ALL_HISTORY,
      listTaskInfo: {},
      logHistory: [],
      XU_LY_FALL_KHOA,
      XU_LY_PASS_KHOA,
      KHAI_GIANG,
      ALL_HISTORY,
      XEP_LOP
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chi tiết tác vụ", name: 'TaskManagementDetail'},
      {title: "Quản lý tác vụ xếp lớp"},
    ]);
    this.getDetailTicketClass()
    this.getHistoryLogTicket()
  },

  methods: {
    handleClick() {
      this.getHistoryLogTicket()
    },
    getDetailTicketClass() {
      this.$store.dispatch(GET_DETAIL_TICKET_CLASS, this.$route.params.id).then((data) => {
        let item = data?.data
        this.listTaskInfo =
            {
              ...item,
              task_id: item?.id,
              type: item?.type,
              responsible: item?.person_in_charge_name,
              branch_name: item?.branch_name,
              course_name: item?.course_name,
              created_at: item?.created_at,
              user_id: item?.user_id,
              user_name: item?.user_name,
              Phone: item?.user_phone,
              classRoom: item?.classroom_name,
              timeOpenClassroom: 1,
              List_voucher: item?.vouchers
            }
      })
    },
    getHistoryLogTicket() {
      let payload = {
        type: this.activeName
      }
      this.$store.dispatch(GET_LOG_HISTORY, {payload: payload, id: this.$route.params.id}).then((res) => {
        this.logHistory = res.data
      })
    },
    getBGStatus(status) {
      if (status === 'Cần làm') {
        return 'badge-warning'
      }
      if (status === 'Hoàn thành') {
        return 'badge-success'
      }
      return 'badge-secondary'
    }
  }

}
</script>

<style>
@media only screen and (max-width: 1024px) {
  #detailTask {
    display: block !important;
  }
}
</style>
