<template>
    <div class="d-flex">
      <div>
        <div class="col-xl-12 mt-3">
            <label for="">Mã tác vụ</label>
            <el-input disabled class="w-100" :value="taskInfo?.task_id"></el-input>
        </div>
        
        <div class="col-xl-12 mt-3">
            <label for="">Loại tác vụ</label>
            <el-input disabled class="w-100" :value="taskInfo?.type"></el-input>
        </div>
        <div class="col-xl-12 mt-3">
          <label for="">Thời gian tạo</label>
          <el-input disabled class="w-100" :value="taskInfo?.created_at"></el-input>
        </div>
        <div class="col-xl-12 mt-3">
            <label for="">Khóa học</label>
            <el-input disabled class="w-100" :value="taskInfo?.course_name"></el-input>
        </div>

        <div class="col-xl-12 mt-3">
            <label for="">Chi nhánh học</label>
            <el-input disabled class="w-100" :value="taskInfo?.branch_name"></el-input>
        </div>
        <div class="col-xl-12 mt-3">
          <label for="">Số điện thoại khách hàng</label>
          <el-input disabled class="w-100" :value="taskInfo?.Phone"></el-input>
        </div>
      </div>

      <div>
         <div class="col-xl-12 mt-3">
            <label for="">Lớp học</label>
            <el-input disabled class="w-100" :value="taskInfo?.classRoom"></el-input>
        </div>

        <div class="col-xl-12 mt-3">
            <label for="">Kết quả thi cuối khóa</label>
            <el-input disabled class="w-100" :value="taskInfo?.final_mark_test?.avg_score"></el-input>
        </div>

        <div class="col-xl-12 mt-3">
            <label for="">Người phụ trách tác vụ</label>
            <el-input disabled class="w-100" :value="taskInfo?.responsible"></el-input>
        </div>

        <div class="col-xl-12 mt-3">
            <label for="">Họ tên khách hàng</label>
            <div  disabled class="d-flex justify-content-between w-100 rounded input-detail">
                <span>{{ taskInfo?.user_name }}</span>
              <span class="show_detail" @click="navigateDetailCustomer(taskInfo)" >Xem chi tiết</span>
            </div>
          </div>  


      </div>
    </div>
  </template>

<script>
import { TYPE_TASK_DIFINE } from '@/core/option/taskArrangePersonal';
export default {
  name: "InfoHandleCourse",
  props: {
    taskInfo: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  components: {},
  data() {
    return {
      typeTaskDifine:TYPE_TASK_DIFINE,
        
    }
  },
  mounted() {
    
  },

  methods: {
    navigateDetailCustomer(taskInfo){
      window.open(this.$router.resolve({ name: 'customer-crm-detail', params: { id : taskInfo.user_id }, query: {best_customer_id: taskInfo.best_customer_id}}
      ).href,`_blank`)
    }
   
  },
};
</script>

<style scoped>
.show_detail {
      color:rgba(0, 102, 255, 1);
      text-decoration: underline;
      cursor: pointer;
      transition: 0.3s;
    }

    .show_detail:hover  {
        color: rgb(0, 0, 255);
    }
</style>